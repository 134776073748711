import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar3 from '../components/navbar3'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Solstis Website</title>
        <meta property="og:title" content="Solstis Website" />
      </Helmet>
      <Navbar3
        text={
          <Fragment>
            <span className="home-text1">@solstis_ai</span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="home-text2">@solstis-ai</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text3">About Us</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text4">Services</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text5">Portfolio</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text6">Contact</span>
          </Fragment>
        }
      ></Navbar3>
      <div className="home-container2">
        <img
          alt="image"
          src="/white_logo_nobackground-600h.png"
          className="home-image"
        />
        <p className="home-text7">AI-Powered Integrations</p>
      </div>
    </div>
  )
}

export default Home
